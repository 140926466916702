import counter from '../modules/utils/counter';

counter(
    'section.section-description',
    '.section-description__single-counter-number'
);

const stickySections = document.querySelectorAll(".section-description--longer");

const handleSectionHeight = (section) => {
    const sectionHeight = section.offsetHeight;

    if (window.innerWidth < 991.98) {
        section.style.height = `${sectionHeight + 120}px`;
    } else {
        section.style.height = "auto";
    }
}

if (stickySections) {
    stickySections.forEach(section => {
        const leftSection = section.querySelector(".section-description__left-col");
    });
}
export default function counter(counterSection, counterItem, speed = 2000, isCircle = false, circlesClass = false) {
    const countersSections = document.querySelectorAll(counterSection);
    const observer = new IntersectionObserver(handleIntersection, {
        root: null, // Use the viewport as the root
        rootMargin: '0px', // No margin
        threshold: 0.2, // 70% of the element is visible
    });

    countersSections.forEach(num => observer.observe(num));

    function numCounter(el, maxCount, speed) {
        var initialNumber = 0;
        var timeInterval = 10;
        var totalSteps = speed / timeInterval;
        var incrementStep = (maxCount - initialNumber) / totalSteps;

        function counter() {
            if (initialNumber <= maxCount) {
                el.innerHTML = Math.floor(initialNumber);
                initialNumber += incrementStep;
            } else {
                el.innerHTML = maxCount;
                clearInterval(counterDelay);
            }
        }

        var counterDelay = setInterval(counter, timeInterval);
    }

    function strokeDashOffsetAnimator(el, maxCount, speed) {
        const maxCountNumber = parseInt(maxCount);
        const percentNumber = parseInt(el.parentElement.querySelector('.section-percents__box-percent--value').dataset.count);
        let initialDashOffset = 1000;
        let currentValue = 1000;
        const fullDashOffset = 588;
        const animationDuration = speed; // Duration in milliseconds
        const steps = Math.floor(animationDuration / (speed / 100)); // Assuming 10ms intervals
    
        const incrementStep = (initialDashOffset - fullDashOffset) / steps;
    
        function animateStrokeDashOffset() {
            if (currentValue >= initialDashOffset - maxCountNumber * incrementStep) {
                el.style.strokeDashoffset = currentValue;
                currentValue -= incrementStep;
            }
            else {
                clearInterval(animationInterval);
            }
        }
    
        el.style.strokeDashoffset = initialDashOffset;
        const timeInterval = animationDuration / percentNumber;
        const animationInterval = setInterval(animateStrokeDashOffset, timeInterval);
    }

    function handleIntersection(entries) {
        entries.map(entry => {
            if (entry.isIntersecting) {
                const counters = document.querySelectorAll(counterItem);
                let circles = [];
                if (isCircle && circlesClass) {
                    circles = document.querySelectorAll(circlesClass);
                }
                counters.forEach((counter, i) =>
                    {
                        numCounter(counter, parseInt(counter.dataset.count), speed)
                        if (isCircle && circles.length > 0) {
                            strokeDashOffsetAnimator(circles[i], parseInt(counter.dataset.count), speed);
                        }
                    }
                );
                observer.unobserve(entry.target);
            }
        });
    }
}
